import React, {useState} from "react";
import {useViewport} from "../../App";
import {useHorizontalScroll} from "../../horizontal-scroll";

import Hero from "./media/service-hero.jpg"

import './services.css'
import './services-mobile.css'
import HomeFooter from "../home/home-footer";
import {Helmet} from "react-helmet-async";

export default function Services(){
    const { width } = useViewport();
    const breakpoint = 768;

    const [stages, setStages] = useState('Design Stage');
    const[stageContent, setStageContent] = useState('Site Survey and Analysis of Site including Analysis of climatic effects on the site; liaisoning with the client and analysis to arrive at requirements of the project which includes a reference of various regulations, market survey and interaction with management. Prepare preliminary design options, their study and analysis; prepare 3-D views of the final approved Designs and models of the project; Submit the project to various approving authorities and liasoning with them.\n')

    function onStageClick(number) {
        if (number === 0) {
            setStages("Design Stage")
            setStageContent('Site Survey and Analysis of Site including Analysis of climatic effects on the site; liaisoning with the client and analysis to arrive at requirements of the project which includes a reference of various regulations, market survey and interaction with management. Prepare preliminary design options, their study and analysis; prepare 3-D views of the final approved Designs and models of the project; Submit the project to various approving authorities and liasoning with them.\n')
        } else if (number === 1) {
            setStages("Working Drawing Stage")
            setStageContent('Prepare all architectural and Detail Drawings which may be required for the execution of the project ., Prepare all the specifications, detail estimates, tender documents, tender Drawings, and their analysis and help clients to arrive at conclusions.\n')
        } else if (number === 2) {
            setStages("Structural Design & Services Drawings")
            setStageContent('Prepare Structural Drawings, analysis, certification etc., Prepare electrical Drawings and Detail, Prepare Plumbing and Sanitary Services. Landscape and any other services which may be required to make a state-of-the-art building.\n')
        } else if (number === 3) {
            setStages("Project Execution Stage")
            setStageContent('Visit the site to ensure that the work is being done as per the drawing, Arrange visits of consultants and coordinate with them; assist in the selection of material process., Coordination  between various contractors, and clients and comment on various issues., Quality Audit, suggestions and recommendations at various stages, Checking of Contractor’s Bill.\n')
        } else if (number === 4) {
            setStages("Project Completion Stage")
            setStageContent('Prepare as-built drawings for the record of management for future reference. Submit the Completion drawings and liaise with authorities for the completion; Settle final bills of contractors; Quality Analysis within 6 months of the Defect Liability period of contractors, and coordinate with them for defects correction.')
        }
    }
    const scrollRef = useHorizontalScroll();
    return(
        <>
            {
                width > breakpoint ?
                    <main className="services" ref={scrollRef}>
                        <Helmet>
                            <title>Our Services</title>
                            <meta name="description" content="We take great pride in the comprehensive range of top-tier services we provide, ensuring your every need is met with excellence and professionalism"/>
                            <link rel="canonical" href={"/services"} />
                        </Helmet>
                        <div className="services-hero-img">
                            <img src={Hero} alt={"Service Hero Image"}/>
                        </div>
                        <div className="services-hero-text">
                            <h2>Need to change this line to something of our own, mainly about services.</h2>
                            <h1>Our Services</h1>
                        </div>
                        <div className="services-provided">
                            <div className="consultancy-services">
                                <h2>Consultancy Services</h2>
                                <div className="table">
                                    <section className="column">
                                        <div className="services-card">
                                            <h3> Technology Services </h3>
                                            <p>
                                                Preparation of Project reports meant for State and University.
                                                The setting of Medical College
                                            </p>
                                        </div>
                                        <div className="services-card">
                                            <h3> Financial Consultancy </h3>
                                            <p>
                                                Assistance will be provided in the preparation of the financial report

                                            </p>
                                        </div>
                                        <div className="services-card">
                                            <h3> Equipment Consultancy </h3>
                                            <p>
                                                Identification of state-of-the-art Equipment, Instruments and allied accessories as per the requirement of the respective councils.
                                            </p>
                                        </div>
                                    </section>
                                    <section className="column">
                                        <div className="services-card">
                                            <h3>
                                                Architectural Consultancy
                                            </h3>
                                            <p>
                                                Site Survey & Contour map based Vastu Shastra Preparation of Master plan of the complex Electrical Layout and HVAC, Plumbing/Sanitary and Waste Management, Fire Protection Services Interior and Exterior technology, Landscaping and green architecture  Overall construction management services with the co-ordination of Technical and Architectural consultants Manpower- Assistance in the Identification of teaching and supporting staff as per the guidelines of the respective governing council/authority such as NMC/DCI/INC/ AYUSH /PCI etc.
                                            </p>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className="management-services">
                                <div className="services-menu">
                                    <h2> Project Management Process </h2>
                                    <div className="stages">
                                        <h3 onClick={()=>onStageClick(0)}>Stage 1</h3>
                                        <h3 onClick={()=>onStageClick(1)}>Stage 2</h3>
                                        <h3 onClick={()=>onStageClick(2)}>Stage 3 </h3>
                                        <h3 onClick={()=>onStageClick(3)}>Stage 4</h3>
                                        <h3 onClick={()=>onStageClick(4)}>Stage 5</h3>
                                    </div>
                                </div>
                                <div className="stages-display">
                                    <h3>{stages}</h3>
                                    <p>{stageContent}</p>
                                </div >

                            </div>
                        </div>
                        <div className="landing-banner media-fill-2"/>
                        <HomeFooter/>
                    </main>
                :
                    <main className="services">
                        <Helmet>
                            <title>Our Services</title>
                            <meta name="description" content="We take great pride in the comprehensive range of top-tier services we provide, ensuring your every need is met with excellence and professionalism"/>
                            <link rel="canonical" href={"/services"} />
                        </Helmet>
                        <div className="services-hero-img">
                            <img src={Hero} alt={"Service Hero Image"}/>
                        </div>
                        <div className="services-hero-text">
                            {/*<h2>Need to change this line to something of our own, mainly about services.</h2>*/}
                            <h1>Our Services</h1>
                        </div>
                        <div className="services-provided">
                            <div className="consultancy-services">
                                <h2>Consultancy Services</h2>
                                <div className="table">
                                    <section className="column">
                                        <div className="services-card">
                                            <h3> Technology Services </h3>
                                            <p>
                                                Preparation of Project reports meant for State and University.
                                                The setting of Medical College
                                            </p>
                                        </div>
                                        <div className="services-card">
                                            <h3> Financial Consultancy </h3>
                                            <p>
                                                Assistance will be provided in the preparation of the financial report

                                            </p>
                                        </div>
                                        <div className="services-card">
                                            <h3> Equipment Consultancy </h3>
                                            <p>
                                                Identification of state-of-the-art Equipment, Instruments and allied accessories as per the requirement of the respective councils.
                                            </p>
                                        </div>
                                    </section>
                                    <section className="column">
                                        <div className="services-card">
                                            <h3>
                                                Architectural Consultancy
                                            </h3>
                                            <p>
                                                Site Survey & Contour map based Vastu Shastra Preparation of Master plan of the complex Electrical Layout and HVAC, Plumbing/Sanitary and Waste Management, Fire Protection Services Interior and Exterior technology, Landscaping and green architecture  Overall construction management services with the co-ordination of Technical and Architectural consultants Manpower- Assistance in the Identification of teaching and supporting staff as per the guidelines of the respective governing council/authority such as NMC/DCI/INC/ AYUSH /PCI etc.
                                            </p>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div className="management-services">
                                <div className="services-menu">
                                    <h2> Project Management Process </h2>
                                    <div className="stages">
                                        <h3 onClick={()=>onStageClick(0)}>Stage 1</h3>
                                        <h3 onClick={()=>onStageClick(1)}>Stage 2</h3>
                                        <h3 onClick={()=>onStageClick(2)}>Stage 3 </h3>
                                        <h3 onClick={()=>onStageClick(3)}>Stage 4</h3>
                                        <h3 onClick={()=>onStageClick(4)}>Stage 5</h3>
                                    </div>
                                </div>
                                <div className="stages-display">
                                    <h3>{stages}</h3>
                                    <p>{stageContent}</p>
                                </div >

                            </div>
                        </div>
                        <div className="landing-banner media-fill-2"/>
                        <HomeFooter/>
                    </main>

            }

        </>
    )
}