import React from "react";

export default function HomeFooter(){
    return(
        <>
            <div className="landing-banner footer">
                <h2>Need more information<br/> about building beyond?</h2>
                <div className="contact-details">
                    <h3>
                        Architect Harish Tripathi & Associates
                    </h3>
                    <h3 >
                        224, Sukhdev Vihar<br/>
                        (Near Escorts Heart Institute)<br/>
                        New Delhi-25 (India)
                    </h3>

                    <h3>
                        Cellphone: +91-9811045628, +91-9910656664 <br/>
                        Phone Number: 91 11-26842684 , 41627270
                    </h3>
                    <h3>
                        E-Mail: arhta1@gmail.com, htji@yahoo.com
                    </h3>
                </div>
                <h3 style={{fontFamily:"Playfair Display, serif", color:"white", fontWeight:"normal", fontSize:"0.8rem", textDecoration:"underline", fontStyle:"italic"}}>Let's Work Together</h3>
            </div>
        </>
    )
}