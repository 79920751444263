import React from "react";
import HeaderNav from "./components/navigation/header-navigation";
import Home from "./pages/home/home";
import Projects from "./pages/projects/project";
import {Route, Routes} from "react-router";
import Services from "./pages/services/services";


const viewportContext = React.createContext({});

const ViewportProvider = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowResize);
        return () => window.removeEventListener("resize", handleWindowResize);
    }, );

    return (
        <viewportContext.Provider value={{ width, height }}>
            {children}
        </viewportContext.Provider>
    );
};

export const useViewport = () => {
    const { width, height } = React.useContext(viewportContext);
    return { width, height };
};

function App() {
  return (
      <ViewportProvider>
          <p
              content={"Arhta Health is an alliance of highly qualified, experienced Architects and Engineers who can support clients especially in establishing Medical Institutes & Health Infrastructure in Modern, Indian and Integrated Medicines."}
              style={{display:"none"}}
          />
          <HeaderNav/>
          <Routes>
              <Route exact path={"/"} element={<Home/>}/>
              <Route exact path={"/portfolio"} element={<Projects/>}/>
              <Route exact path={"/services"} element={<Services/>}/>
          </Routes>
      </ViewportProvider>
  );
}

export default App;
