import React from "react";

export default function Empanelment(){
    return(
        <>
            <div className="landing-banner about empanelment">

                <section>
                    <div className="empanelment-section">
                        <h2>Empanelment and Associations</h2>
                        <br/>
                        <div className="associations">
                            <section>
                                <text>Construction & Design<br/> Services UP Jalnigam</text>
                                <text>Hindustan Steel Works Construction Ltd.</text>
                                <text>UP Samaj Kalyan Nirman Nigam</text>
                                <text>MP Housing Board</text>


                            </section>
                            <section>
                                <text>Jamia Millia <br/>Islamia (University)</text>
                                <text>Picaddily Group</text>
                                <text>JNV (New Delhi)</text>
                                <text>SRMPC (New Delhi)</text>
                                <text>BBJ (Const. Kolkata)</text>
                                <text>DHPD</text>

                            </section>
                            <section>
                                <text>BHEL</text>
                                <text>IOCL</text>
                                <text>NPCC</text>
                                <text>CGPWD (Chhattisgarh)</text>
                                <text>UP Rajkiya Nirman Nigam</text>
                                <text>MGCC (Meghalaya)</text>
                            </section>
                        </div>
                    </div>
                    <div className="client-section">
                        <h2> Clients </h2>
                        <br/>
                        <div className="clients">
                            <section>
                                <text>Maa Lalita Research Foundation</text>
                                <text>Bakson Group</text>
                                <text>IOP(ICMR)</text>
                                <text>UPSKNN</text>
                            </section>
                            <section>
                                <text>Jeevan Jyoti Hospital</text>
                                <text>World College Group</text>
                                <text>C& DS (UP Jal Nigam)</text>
                                <text>UPRNN</text>
                            </section>
                        </div>
                    </div>


                </section>
            </div>
        </>
    )
}