import React from "react";
import {NavLink} from "react-router-dom";
import HamburgerIcon from './menu.png'
import {useViewport} from "../../App";

export default function HeaderNav(){

    function onclickHamburger(){
        let mainContainer = document.getElementsByClassName("disappearing-layout-menu")[0];
        mainContainer.style.transform = "translateY(0)";
        mainContainer.style.animation = "slideIn 0.4s ease-in-out"
    }

    function onCloseClick() {
        let mainContainer = document.getElementsByClassName("disappearing-layout-menu")[0];
        mainContainer.style.transform = "translateY(-100vh)";
        mainContainer.style.animation = "slideOut 0.4s ease-in-out"
    }

    return(
        <nav>
            <img src={HamburgerIcon} alt={"Menu"} onClick={()=>onclickHamburger()}/>
            <div className="disappearing-layout-menu">
                <section>
                    <NavLink to={'/'} onClick={()=>onCloseClick()}>About Us</NavLink>
                    <NavLink to={'/services'} onClick={()=>onCloseClick()}>Services</NavLink>
                    <NavLink to={'/portfolio'} onClick={()=>onCloseClick()}>Portfolio</NavLink>
                    <NavLink to={'/'} onClick={()=>onCloseClick()}>Contact Us</NavLink>
                    <p onClick={()=>onCloseClick()}>Close</p>
                </section>
            </div>

        </nav>
    )
}