import React, {useState} from "react";
import './projects.css'
import './project-mobile.css'

import {useViewport} from "../../App";
import {useHorizontalScroll} from "../../horizontal-scroll";

import Backsons from "./media/baksons.jpg"
import SLIMS from "./media/slims.jpg"
import Bawal from "./media/bawal.jpg"
import Hapur from "./media/hapur.jpg"
import MCH from "./media/mch.jpg"
import Jyoti from "./media/jyoti.jpg"
import Bhavana from "./media/bhavana.jpg"
import Nursing from "./media/nursing.jpg"
import Gouri from "./media/gouri.jpg"

import Hero from "./media/project-hero.jpg";
import ProjectLayoutCard from "./project-layout";
import ProjectCard from "./project-card";

import {
    BaksonImages,
    BawalImages,
    BhavanaImages,
    GouriImages,
    HapurImages,
    JyotiImages,
    MCHImages,
    NursingImages,
    SLIMSImages
} from "./images";
import {Helmet} from "react-helmet-async";
import HomeFooter from "../home/home-footer";

export default function Projects(){
    const { width } = useViewport();
    const breakpoint = 768;

    const scrollRef = useHorizontalScroll();

    const [projectContent, setProjectContent] = useState('');
    const [projectTitle, setProjectTitle] = useState('');
    const [projectImages, setProjectImages] = useState('');

    return(
        <>
            {
                width > breakpoint ?
                    <main className="projects" ref={scrollRef}>
                        <Helmet>
                            <title>Our Portfolio</title>
                            <meta name="description" content="Our captivating and diverse architectural portfolio for inspiration and insight"/>
                            <link rel="canonical" href={"/portfolio"} />
                        </Helmet>
                        <div className="project-hero-img">
                            <img src={Hero} alt={"Service Hero Image"}/>
                        </div>
                        <div className="projects-hero-text">
                            <h2></h2>
                            <h1>Our Portfolio</h1>
                        </div>
                        <div className="projects-img">
                            <ProjectCard image={Backsons}
                                         images={BaksonImages}
                                         title={"Bakson's Homeopathic Medical College, Greater Noida"}
                                         content={" "}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={Jyoti}
                                         images={JyotiImages}
                                         title={"Jeevan Jyoti Hospital and Research Centre, Allahabad"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={Bhavana}
                                         images={BhavanaImages}
                                         title={"Bhavana Old Age Home, Yieda"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={MCH}
                                         images={MCHImages}
                                         title={"Mother and Child Hospital, Aligarh"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={Nursing}
                                         images={NursingImages}
                                         title={"College of Nursing, Aligarh"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={Hapur}
                                         images={HapurImages}
                                         title={"GS Medical College and Hospital, Hapur"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={Gouri}
                                         images={GouriImages}
                                         title={"Gouri Devi Institute of Medical Sciences, Durgapur"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={Bawal}
                                         images={BawalImages}
                                         title={"ESIC Hospital, Bawal"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={SLIMS}
                                         images={SLIMSImages}
                                         title={"SLIMS Dental College"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                        </div>

                    </main>
                    :
                    <main className="projects">
                        <Helmet>
                            <title>Our Portfolio</title>
                            <meta name="description" content="Our captivating and diverse architectural portfolio for inspiration and insight"/>
                            <link rel="canonical" href={"/portfolio"} />
                        </Helmet>
                        <div className="project-hero-img">
                            <img src={Hero} alt={"Service Hero Image"}/>
                        </div>
                        <div className="projects-hero-text">
                            <h2></h2>
                            <h1>Our Portfolio</h1>
                        </div>
                        <div className="projects-img">
                            <ProjectCard image={Backsons}
                                         images={BaksonImages}
                                         title={"Bakson's Homeopathic Medical College, Greater Noida"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={Jyoti}
                                         images={JyotiImages}
                                         title={"Jeevan Jyoti Hospital and Research Centre, Allahabad"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={Bhavana}
                                         images={BhavanaImages}
                                         title={"Bhavana Old Age Home, Yieda"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={MCH}
                                         images={MCHImages}
                                         title={"Mother and Child Hospital, Aligarh"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={Nursing}
                                         images={NursingImages}
                                         title={"College of Nursing, Aligarh"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={Hapur}
                                         images={HapurImages}
                                         title={"GS Medical College and Hospital, Hapur"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={Gouri}
                                         images={GouriImages}
                                         title={"Gouri Devi Institute of Medical Sciences, Durgapur"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={Bawal}
                                         images={BawalImages}
                                         title={"ESIC Hospital, Bawal"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                            <ProjectCard image={SLIMS}
                                         images={SLIMSImages}
                                         title={"SLIMS Dental College"}
                                         content={""}
                                         setProjectTitle={setProjectTitle}
                                         setProjectContent={setProjectContent}
                                         setProjectImages={setProjectImages}
                            />
                        </div>
                        <HomeFooter/>
                    </main>
            }

            <div className="disappearing-layout-project">
                <ProjectLayoutCard title={projectTitle} content={projectContent} images={projectImages}/>
            </div>
        </>
    )
}