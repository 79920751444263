import React, {useState} from "react";

export default function ProjectLayoutCard(props){

    const [currentImage, setCurrentImage]= useState(0);
    const [disabledNext, setDisabledNext] = useState(false)
    const [disabledPrev, setDisabledPrev] = useState(true)

    function onClickClose(){
        let mainContainer = document.getElementsByClassName("disappearing-layout-project")[0];
        mainContainer.style.transform = "translateY(-100vh)";
        mainContainer.style.animation = "slideOut 0.4s ease-in-out"
        setTimeout(()=>{
            setCurrentImage(0);
            setDisabledPrev(true);
            setDisabledNext(false)
        },1000)
    }

    function nextImage(currentIndex){
        if(currentIndex < props.images.length-1){
            setCurrentImage(currentIndex+1)
            setDisabledPrev(false)
            if(currentIndex+1 === props.images.length-1){
                setDisabledNext(true)
            }
        }
        else{
            setDisabledNext(true)
        }
    }

    function prevImage(currentIndex){
        if(currentIndex > 0){
            setCurrentImage(currentIndex-1)
            setDisabledNext(false)
            if(currentIndex-1 === 0){setDisabledPrev(true)}
        }
        else{
            setDisabledPrev(false)
        }
    }

    return(
        <div className="project-details-layout">
            <section>
                <h1>{props.title}</h1>
                <h2>{props.content}</h2>
            </section>
            <div className="slider">
                <img src={props.images[currentImage]}  alt={"Image"}/>
                {
                    props.images.length > 1 ?
                        <div style={{display:"flex", width:"100%", justifyContent:"space-between", marginTop:"24px"}}>
                            <button disabled={disabledPrev} onClick={()=>prevImage(currentImage)}>Previous</button>
                            <button disabled={disabledNext} onClick={()=>nextImage(currentImage)}>Next</button>
                        </div>
                    :
                        <></>
                }


            </div>
            <h4 onClick={()=>onClickClose()}>Close</h4>
        </div>
    )
}