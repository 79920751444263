import React from "react";

export default function AboutUs(){
    return(
        <>
            <div className="landing-banner about">
                <div className="about-us">
                    <h2> About Us </h2>
                    <br/>
                    <p>
                        Arhta Health is an alliance of highly qualified, experienced Architects and Engineers who can support clients especially in establishing  Medical Institutes & Health Infrastructure in Modern, Indian and Integrated Medicines.
                        <br/>
                        <br/>
                        We provide professional guidance spanning a wide range of services for establishing Medical/Dental/AYUSH/ Pharmacy/ Nursing Colleges and associated Teaching Hospitals and their supporting services in accordance with the guidelines of respective councils and ministries.
                        <br/>
                        <br/>
                        Our expertise lies in the creation of Health infrastructure, extended through our consultancy services.
                    </p>
                </div>
                <div className="special-interests">
                    <h1>
                        Sustainability <br/> Green Building <br/> Holisticism <br/> Materials <br/> Vastu <br/> Ekistics <br/>
                    </h1>
                </div>
            </div>
        </>
    )
}