import React from "react";
import Landing from "./landing-page";
import AboutUs from "./about-us";
import Vision from "./vision";
import Team from "./team";
import {useHorizontalScroll} from "../../horizontal-scroll";

import './landing.css'
import './landing-mobile.css'
import Empanelment from "./empanelment";
import HomeFooter from "./home-footer";
import {useViewport} from "../../App";
import {Helmet} from "react-helmet-async";




export default function Home(){

    const { width } = useViewport();
    const breakpoint = 768;

    const scrollRef = useHorizontalScroll();

    return (
            width > breakpoint ?
            <main ref={scrollRef}
                  className="app-container"
            >
                <Helmet>
                    <title>Arhta Health</title>
                    <meta name="description" content="Delivering holistic, practical and economical solutions to strengthen the health care institution and infrastructure in India and abroad."/>
                    <link rel="canonical" href={"/"} />
                </Helmet>
                <Landing/>
                <AboutUs/>
                <Vision/>
                <div className="landing-banner media-fill"/>
                <Team/>
                <div className="landing-banner media-fill-2"/>
                <Empanelment/>
                <div className="landing-banner media-fill-3"/>
                <HomeFooter/>
            </main>
            :
            <main
                  className="mobile-app-container"
            >
                <Landing/>
                <AboutUs/>
                <Vision/>
                <div className="landing-banner media-fill"/>
                <Team/>
                <Empanelment/>
                <div className="landing-banner media-fill-3"/>
                <HomeFooter/>
            </main>
    )
}