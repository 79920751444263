import React from "react";

export default function Vision(){
    return(
        <>
            <div className="landing-banner about vision">
                <div className="vision">
                    <h2> Vision </h2>
                    <br/>
                    <p>
                        Arhta Health aspires to consult in the Health care sector with specialization in the establishment of Medical/, Dental/, AYUSH/ Nursing Colleges, associated Hospitals, Hostels, Paramedical Institutes etc by providing both private and public sectors with holistic principles and innovative approaches.
                        <br/>
                        <br/>
                        {/*Our team comprises pioneers in the medical sector with 35 to 40 years of combined experience working together to deliver holistic, practical and economical solutions in establishing and strengthening the Health care Institutions & Healthcare Infrastructure in India and abroad.*/}
                    </p>
                </div>
            </div>
        </>
    )
}