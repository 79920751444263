const BaksonImages= [
    require('./media/baksons/Baksons-3.jpg'),
    require('./media/baksons/Baksons-2.jpg'),
    require('./media/baksons/Baksons.jpg'),
    require('./media/baksons/Baksons-4.jpg'),
    require('./media/baksons/Baksons-6.jpg'),
    require('./media/baksons/Baksons-7.jpg')
]

const JyotiImages=[
    require('./media/jyoti/jyoti-1.jpg'),
    require('./media/jyoti/jyoti-2.jpg'),
    // require('./media/jyoti/jyoti-3.jpg')
]

const BhavanaImages= [
    require('./media/bhavana/Baksons.jpg'),
    require('./media/bhavana/Baksons-2.jpg'),
    require('./media/bhavana/Baksons-3.jpg'),
    require('./media/bhavana/Baksons-4.jpg')
]

const MCHImages= [
    require('./media/mch/MCH.jpg'),
    require('./media/mch/MCH-2.jpg'),
    require('./media/mch/MCH-3.jpg'),
    require('./media/mch/MCH-4.jpg'),
    require('./media/mch/MCH-5.jpg')
]

const NursingImages=[
    require('./media/nursing/Cover.jpg'),
    require('./media/nursing/Cover-2.jpg'),
    require('./media/nursing/Cover-3.jpg')
]

const GouriImages=[
    require('./media/gouri/one.jpg'),
    require('./media/gouri/two.jpg'),
    require('./media/gouri/three.jpg')
]

const HapurImages=[
    require('./media/hapur/one.jpg'),
    require('./media/hapur/two.jpg'),
    require('./media/hapur/three.jpg')
]

const SLIMSImages=[
    require('./media/misc/slims.jpg')
]

const BawalImages=[
    require('./media/misc/bawal.jpg')
]

module.exports={
    BaksonImages,
    JyotiImages,
    BhavanaImages,
    MCHImages,
    NursingImages,
    GouriImages,
    HapurImages,
    SLIMSImages,
    BawalImages
}