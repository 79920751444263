import React from "react";

export default function ProjectCard(props){
    function onClickLearnMore(title, content, images){
        let mainContainer = document.getElementsByClassName("disappearing-layout-project")[0];
        mainContainer.style.transform = "translateY(0)";
        mainContainer.style.animation = "slideIn 0.4s ease-in-out"

        props.setProjectTitle(title);
        props.setProjectContent(content);
        props.setProjectImages(images);
    }

    return(
        <div className="project-card">
            <img src={props.image} alt={"Backsons Architecture"}/>
            <h2> {props.title} </h2>
            <h3 style={{fontFamily:"Poppins", fontWeight:"500", fontSize:"0.8rem", marginTop:"24px", textDecoration:"underline", cursor:"pointer"}}
                onClick={()=>onClickLearnMore(props.title, props.content, props.images)}>
                Learn More
            </h3>
        </div>
    )
}