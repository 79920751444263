import React from "react";


export default function Landing(){
    return(
        <>
        <div className="landing-banner">
            <section>
                <h1> arhta health </h1>
                <h3>Architect Harish Tripathi & Associates</h3>
                <p>
                    Delivering holistic, practical and economical solutions to strengthen the health care institution and infrastructure in India and abroad.
                </p>
            </section>
        </div>
        </>
    )
}