import React, {useState} from "react";
import Harish from "./media/harish-trial.jpg"
import Jyoti from "./media/jyoti-trial.jpg"
import {useViewport} from "../../App";

export default function Team(){

    const { width } = useViewport();
    const breakpoint = 768;

    const [aboutBio, setAboutBio] = useState('');

    function onclickLearnMore(string){
        let mainContainer = document.getElementsByClassName("disappearing-layout")[0];
        mainContainer.style.transform = "translateY(0)";
        mainContainer.style.animation = "slideIn 0.4s ease-in-out"

        if(string==="Harish"){setAboutBio(string);}
        else{setAboutBio(string)}

    }

    function oncCloseClick(){
        let mainContainer = document.getElementsByClassName("disappearing-layout")[0];
        if(width > breakpoint){
            mainContainer.style.transform = "translateY(-100vh)";
            mainContainer.style.animation = "slideOut 0.4s ease-in-out"
        }
        else{
            mainContainer.style.transform = "translateY(-100vh)";
            mainContainer.style.animation = "slideOut 0.4s ease-in-out"
        }



    }

    return(
        <>
            <div className="landing-banner about team">
                <div className="teams-section">
                    <h2> Our Team </h2>
                    <br/>
                    <p>
                        Our team comprises pioneers in the medical sector with 35 to 40 years of combined experience working together to deliver holistic, practical and economical solutions in establishing and strengthening the Health care Institutions & Healthcare Infrastructure in India and abroad.
                    </p>
                </div>
                <div className="core-team">
                    <section>
                        <img src={Harish} alt={"Harish Tripathi"}/>
                        <h3 style={{marginTop:"12px"}}>
                            Dr Harish Tripathi
                        </h3>
                        <h3 style={{fontFamily:"Poppins", fontWeight:"200", fontSize:"1rem"}}>
                            Principal Architect
                        </h3>
                        <h3 style={{fontFamily:"Poppins", fontWeight:"500", fontSize:"0.8rem", marginTop:"24px", textDecoration:"underline", cursor:"pointer"}}
                        onClick={() => onclickLearnMore("Harish")}>
                            Learn More
                        </h3>
                    </section>
                    <section>
                        <img src={Jyoti} alt={"Jyoti Tripathi"}/>
                        <h3 style={{marginTop:"12px"}}>
                            Jyoti D. Tripathi
                        </h3>
                        <h3 style={{fontFamily:"Poppins", fontWeight:"200", fontSize:"1rem"}}>
                            Principal Consultant
                        </h3>
                        <h3 style={{fontFamily:"Poppins", fontWeight:"500", fontSize:"0.8rem", marginTop:"24px", textDecoration:"underline", cursor:"pointer"}}
                            onClick={() => onclickLearnMore("Jyoti")}>
                            Learn More
                        </h3>
                    </section>
                </div>
            </div>
            <div className="landing-banner about other">
                <div className="consultant-section">
                    <h2> Associate Consultants </h2>
                    <br/>
                    <div className="consultants">
                        <section>
                            <text>Harish Mishra, Anil Shukla, P.C. Sharma, Sharad Gupta, S S Beniwal, Revati Raman Singh, Kalesh Babu<b>Structural Consultant</b></text>
                            <text>Amarjeet Singh, Ajai Kumar, M.P Varma, D P Arora<b>Electrical Consultant</b></text>
                            <text>Pramod Sharma, Dr Umesh Rai, Somnath Behera, Rajesh Singh<b>Plumbing Consultant</b></text>
                            <text>Udayan Chaudhary,  Sanjai  Khatri, Asif<b>HVAC Consultant</b></text>
                            <text>Vinita Vijayan, Salim Akhtar, Vipul Singh, Prem Tiwari<b>Landscape</b></text>
                            <text>Abbas <b>MEP Consultants</b></text>


                        </section>
                        <section>
                            <text>Rajesh Gupta (New Delhi), Dr Utpal Barua,(Guwahati), Nagadi (Bangalore), Marshal Soil Testing Raipur, Mrs Rupa Dutta, NN Sirithia (Alld), Shah (Lko), D K Khanna (Jammu)<b>Soil Consultant</b></text>
                            <text>Sanjai Goel, Ashok Kapoor<b>Chartered Accountants</b></text>
                            <text>Anil Gupta, Amit Chaudhary<b>Estimating & Costing</b></text>
                            <text>Satish Kumar, Amitesh Mishra <b>Model &  Computer Imaging</b></text>
                            <text>Pankaj Chaddha<b>DMA Kitchen Consultant</b></text>
                            <text> Satish Kumar Sharma (HCA)<b>Engineering & Management Consultant</b></text>
                            <text>EQMS New Delhi, Gaurav Gupta<b>Green Building & Environment</b></text>
                        </section>
                        <section>
                            <text>Asim Dey (Guwahati,) Rao Devender (New Delhi) Hitesh Blue Line (Gandhi Nagar), Prithvi Raj Chauhan (Raipur), Subodh Singhal (Gwalior)<b>Surveyors</b></text>
                            <text>Mr B. Sasi Kumar<b>OT Consultants</b></text>
                            <text>Mr  B. Sasi Kumar<b>MRD Consultants</b></text>
                            <text>Mr  B. Sasi Kumar<b>Waste Management Consultant</b></text>
                            <text>Mr B Sasi Kumar<b>Medical Gas & Vacuum</b></text>
                            <text>Amit Sen Vaidya, Madhu Chauhan<b>Kitchen Consultant</b></text>

                        </section>
                    </div>
                </div>
                <section>
                    <div className="client-section">
                        <h2> Infrastructure </h2>
                        <br/>
                        <div className="clients">
                            <p style={{fontStyle:"normal", fontSize:"1rem"}}>
                                2500 sqft  Office and Studio at  Sukhdev Vihar, New Delhi, India.Work on the latest software of Design (CAD). Associate Architects, Draftsmen, Interior Designers Engineers as in-house staff.
                            </p>
                        </div>
                    </div>
                    <div className="advisor-section">
                        <h2> Advisors </h2>
                        <br/>
                        <div className="advisors">
                            <section>
                                <text>B Sasi Kumar (New Delhi)</text>
                                <text>Dr P K Sinha  (New Delhi)</text>
                                <text>Dr D S Mishra (UP)</text>
                                <text>Dr R P Singh (UP)</text>
                                <text>Dr G K Singh  (Jharkhand)</text>
                                <text>Mr Prem Tiwari (Landscape)</text>
                                <text>K K Mitra  (Retd Architect PWD)</text>
                            </section>
                            <section>
                                <text>G Sunderani (Retd BHEL)</text>
                                <text>Mr A K Sharma  (Ret SDG – CPWD)</text>
                                <text>Dr Kamal Taori Ret IAS</text>
                                <text>Laxmi Dass</text>
                                <text>Trilok Sharma (Journalist – Noida)</text>
                                <text>Mr S K Jain (ex Dir (Eng) – HSCC)</text>
                                <text>Dr S M Akhtar  (HOD (Retd) – JMI, New Delhi)</text>
                            </section>
                        </div>
                    </div>
                </section>
            </div>

            <div className="disappearing-layout">
                {
                    aboutBio === "Harish" ?
                        <section>

                            <h1> Dr Harish<br/>Tripathi </h1>
                            <p>
                                Dr Harish Tripathi has more than 25 yrs of professional experience .He did his graduation in Architecture from Govt. College of Architecture Lucknow ,India in the year 1993. He completed ‘Masters in Ekistics’ (Science of human settlement) and PhD Architecture (parallel in Ekistics and Vedic Philosophy towards Ideal Human Settlement) from Faculty of Architecture & Ekistics, Jamia Millia Islamia New Delhi (A Central University), India. Dr. Tripathi is Fellow of Indian Institute of Architects, Associate Institute of Indian Interior Designers, Registered under Council of Architecture and a Fellow of Institution of Valuers.
                                <br/><br/>
                                He worked with Architect Satish Gujral (Renowned Architect, Painter & Sculptor) & Mohit Gujral on various Architectural and Interior Projects (1994-1997). He was Partner of Architectural firm ‘Design Nexus’ from 1997-2000. He is Principal Architect of the group (Arhta) Architect Harish Tripathi and Associates’ since 2000. The group is involved in designing of various Commercial, Institutional, Hospitality, Residential and Industrial projects in India and abroad. The group has a strong and continuous support of his wife Jyoti D Tripathi (B.Tech, MBA, and Dip Interior Design) who is Principal Consultant & interior Designer of the Group. Dr Tripathi is visiting faculty of ‘faculty of Architecture & ekistics, Jamia Millia Islamia New Delhi (A Central University). He is recipient of Numerous Awards and his work has been published in various Indian and International publications.
                                <br/><br/>
                                He designed more than 100 Projects in the field of Architecture, Interiors, Landscape and Master planning in Commercial, Institutional, Residential, Industrial, Offices etc. Dr Tripathi is presently engaged with a number of reputed Indian & Foreign organizations like  C&DS,HSCL, UPSCIDCO,  MP Housing Board,UPRNN , KVS  , BHEL, Hudco,  JMI (University) , Picaddily Group, JNV New Delhi ,SRMPC New Delhi,BBJ Kolkata, DHPD, IOCL, NBCC, NPCC ,Ryan International , Indus Valley Group ,Supertech , HCC , AMU, Vinayaka Homes ,STPI, MGCC, CGPWD ,DUD,  ASMS Noida and many indivdual reputed clients.
                            </p>
                            <h2 onClick={()=>oncCloseClick()}>Close</h2>

                        </section>

                        :
                        <section>
                            <h1> Jyoti D <br/> Tripathi </h1>
                            <p>
                                Jyoti D Tripathi  studied management (MBA)and Engineering (B.Tech Ag Eng) from famous Allahabad University. In the beginning of her carrier she worked with various organizations at management positions. She became part of Architect Harish Tripathi & Associates in 1998. She studied and pursued interior Designing while working with Arhta. She is the principal Consultant of the group .She is responsible for Interior Designing projects designed by the group .She is also responsible for management of the group as well as management Consultancy pursued by the group.She worked with Ajai Industrial Corporation New Delhi .She is Principal Consultant & Interior Designer ‘Architect Harish Tripathi & Associates’ since 1998.
                                <br/><br/>
                                She has handled various interior projects done by the group as Offices, Hotels, Show-rooms, Residential , Hospitals, Schools and Commercial including Furniture Designing and detailing etc .She Has been involved with all the stages of the Interior Design Projects designed by the group. She conceptualized the project from Formulation stage and have been dealing with last details of the project.She has keen interest in Specifications formulation, Selection of Materials, Selection of Furniture and Art work, Detailing, Coordination of Various Services (like Electrical, Plumbing & Fire fighting, Air-conditioning etc ).She has been a key element for the Project Management Consultancy pursued by the Group.
                                <br/><br/>
                                She is the head of ‘Furniture Design and interior execution wing’ of the group in the name of ‘Arc Asia Group’ and also looks after the production of its Noida Factory.She is actively involved with Green Interiors & Sustainability through her works, Design philosophy and also through her NGO AHSD (Association for Holistic and Sustainable Development).
                            </p>
                            <h2 onClick={()=>oncCloseClick()}>Close</h2>
                        </section>

                }
            </div>
        </>
    )
}